@font-face {
    font-family: 'Plus Jakarta Sans';
    font-weight: normal;
    font-style: normal;
    src: url('/ui/fonts/Camfil/webfonts/PlusJakartaSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-style: normal;
    src: url('/ui/fonts/Camfil/webfonts/PlusJakartaSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans SC';
    font-weight: normal;
    font-style: normal;
    src: url('/ui/fonts/Camfil/webfonts/NotoSansSC-VariableFont_wght.ttf') format('truetype');
}
